* {
    box-sizing: border-box;
}

body {
    margin: 0;
    background-color: #5ce1e6;
}

h1 {
    text-align: center;
}

.contact-form {
  margin: auto;
  width: 100%;
  height: 100%;
  border: 3px solid black;
  padding: 10px;
  padding-top: 20px;
  vertical-align: center;
  text-align: left;
}

.contact-form textarea {
    margin-left: 5px;
    width: 100%;
    height: 200px;
    resize: none;
}

.contact-form label {
    margin-right: 5px;
    margin-left: 5px;
}

.contact-form input {
    margin-left: 5px;
    margin-bottom: 5%;
    width: 100%;
}


.horizontal-layout {
    height: 100%;
    width: 100%;
    display: flex;
    margin: auto;

}

.container {
    background-color: #5ce1e6;
}

.standard-frame {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    padding-top: 10%;
    background-color: white;
}

.site-title {
    font-size: 2rem;
    max-width: 10%;
    max-height: 10%;
}

.site-title img {
    left: 0%;
    top: 0%;
    max-width: 50rem;
    max-height: 50rem;
}

.about-img{
    height: 80%;
    width: 60%;
}

.card-img {
    height: 40%;
    width: 60%;
}

.nav-items a {
    text-decoration: none;
    color: black
}

.nav {
    position: fixed;
    top: 0%;
    width: 100%;
    background-color: #5ce1e6;
    color: white;
    display: flex;
    text-decoration: none;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
    max-height: fit-content;
    overflow: hidden;
}

.nav a img{
    transform: scale(2.3);
    margin-left: 25px;
    margin-top: 5px;
}

.nav-list {
    list-style-type: none;
    background: #5ce1e6;
    display: flex;
    text-decoration: none;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    position: relative;
}

.nav-items {
    margin-right: 20px;
    font-size: 20px;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.nav-btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: #000;
    font-size: 18px;
}

.headline {
    font-weight: bold;
    font-size: 50px;
    color: red;
}

.after-headline {
    margin-bottom: 25%;
}

.flex-container {
    display: flex;
    flex-direction: row;
}

.flex-item {
    flex: 33%
}

.flex-item-50 {
    flex: 50%
}

.flex-item-70 {
    flex: 70%;
}

.flex-item-30 {
    flex: 30%;
}

.centered {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.padded-5 {
    padding-left: 5%;
    padding-right: 5%;
}

.padded-right {
    padding-right: 5%;
}

.padded-left {
    padding-left: 5%;
}

.padded-below {
    padding-bottom: 2.5%;
    margin-bottom: 2.5%;
}

.med-height {
    max-height: 300px;
}

.line-break {
    background-color: black;
    border: 2px solid black
}

.greyed {
    color: grey;
}

.founder-title {
    line-height: 5px;
}

.padded-top {
    padding-top: 10%;
    margin-top: 5%;
}


@media screen and (max-width: 600px){
    .site-title {
        position: relative;
        font-size: 2rem;
        left: -15%;
        max-width: 5%;
        max-height: 5%;
    }
    .nav {
        flex-direction: column;
        flex: 1;
    }

    .nav-list {
        flex-direction: column;
        height: auto;
    }
    .nav-items:nth-child(1){
        border-top: 1px solid rgba(255, 255, 255, 0.555);
        width: 1000px;
        margin-top: 0%;
    }
    .nav-items {
        width: 100%;
        height: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.555);
        text-align: center;
        margin-right: 0px;
        padding: 20px 0;
    }
    .nav-btn {
        display: block;
        line-height: 40%;
        background-color: white;
        color: black;
        border-color: white;
        border-radius: 0%;
        padding-bottom: 20px;
    }

    .headline {
        font-weight: bold;
        font-size: 30px;
        margin-top: 25%;
    }

    .standard-frame {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 5%;
        padding-top: 10%;
        margin-top: 15%;
        margin-bottom: 5%;
    }

    .flex-container {
        flex-direction: column;
    }

    .padded-right {
        padding-right: 0%;
    }
    
    .padded-left {
        padding-left: 0%;
    }

    .med-height {
        max-height: 3000px;
    }
}




.btn-home {
    background-color: black;
    color: white;
    font-size: 20px;
    margin-top: 5%;
    border-color: black;
    padding: 10px 60px;
}

.footer {
    position: relative;
    background-color: #5ce1e6;
    width: 100%;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    margin-bottom: 15px;
    margin-top: 2.5px;
    height: 2.5px;
    text-align: center;

}

.footer a {
    text-decoration: none;
}

.defn-box {
    background-color: lightgrey;
    font-weight: bolder;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
    
}

.defn {
    line-height: 1px;
    padding-bottom: 2.5%;
}

.small-label {
    font-size: x-small;

}

.bold-italic {
    font-style: italic;
    font-weight: bolder;
}

.larger-text {
    font-size: 2.5rem;
}